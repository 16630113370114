import * as React from "react";
import * as ReactDOM from "react-dom";

const noaaHourlyURL = "https://api.weather.gov/gridpoints/PHI/43,73/forecast/hourly";
const noaaForecastURL = "https://api.weather.gov/gridpoints/PHI/43,73/forecast";
const DAYS = ["Sun", "Mon", "Tues", "Wed", "Thurs", "Fri", "Sat"];

function fetchAjax(url: string, assignFunc: any) {
  return fetch(url).then((response) => {
    return response.json().then((data) => assignFunc(data));
  });
}

function hoursFromTimestamp(timestamp: string): string {
  const today = new Date();
  const parsedTime = new Date(timestamp);
  const hours = parsedTime.getHours();
  const num = hours > 12 ? hours - 12 : hours;
  const ampm = hours >= 12 ? "pm" : "am";
  const formattedTime =  num + ":00" + ampm;
  if (today.getDate() !== parsedTime.getDate()) {
    return DAYS[parsedTime.getDay()] + " " + formattedTime;
  } else {
    return formattedTime;
  }
}

function groupByDay(items: object[], dateProperty: string): object {
  return items.reduce((grouper, item) => {
    const starts = new Date(item[dateProperty]);
    if (grouper.hasOwnProperty(starts.getDay())) {
      grouper[starts.getDay()].push(item);
    } else {
      grouper[starts.getDay()] = [item];
    }
    return grouper;
  }, {});
}

function filterNextWeek(items: object[], dateProperty: string): object[] {
  const today = new Date();
  return items.filter((item) => {
    const starts = new Date(item[dateProperty]);
    if (starts.getDay() === today.getDay()) {
      return starts.getDate() === today.getDate();
    } else {
      return true;
    }
  });
}

interface IForecast {
  number: number;
  name: string;
  startTime: string;
  endTime: string;
  isDaytime: boolean;
  temperature: number;
  temperatureUnit: string;
  temperatureTrend: void;
  windSpeed: number;
  windDirection: string;
  icon: string;
  shortForecast: string;
  detailedForecast: string;
}

function Hour(props: IForecast) {
  const formattedTime = hoursFromTimestamp(props.startTime);
  return <li className="hour card">
    <div className="columns is-mobile">
      <div className="column is-one-fifth">
        <figure className="image is-48x48">
          <img src={props.icon} alt="weather icon" />
        </figure>
      </div>

      <div className="column is-two-fifths">
        <p>
          <span className="title is-4">
              {props.temperature}&#xb0;
          </span>
          <br/>
          <time dateTime={props.startTime}>
              {formattedTime}
          </time>
        </p>
      </div>

      <div className="column is-two-fifths">
        <span className="is-size-7">
          {props.shortForecast}.<br/>
          {props.windSpeed} / {props.windDirection}.
        </span>
      </div>
    </div>
  </li>;
}

interface ButtonProps {
  text: string;
  onClick(e: React.MouseEvent): void;
}

function Button(props: ButtonProps) {
  return (
    <button
      onClick={props.onClick}
      className="button"
      type="button"
    >
      {props.text}
    </button>
  );
}

interface ListProps {
  hours: IForecast[];
  show: number;
}

interface ListState {
  show: number;
}

class HourlyList extends React.Component<ListProps, ListState> {
  private hoursIncrement = 5;

  constructor(props: ListProps) {
    super(props);
    this.state = {
      show: props.show,
    };
  }

  public addMoreHours = (e: React.MouseEvent) => {
    this.setState({show: this.state.show + this.hoursIncrement});
  }

  public subtractHours = (e: React.MouseEvent) => {
    this.setState({show: this.state.show - this.hoursIncrement});
  }

  public render() {
    return <>
      <ol className="cards">
        {this.listItems()}
      </ol>
      <div className="button-group">
        {this.actionButtons()}
      </div>
    </>;
  }

  private listItems() {
    const hoursToList = this.props.hours.slice(0, this.state.show);
    return hoursToList.map((hour) =>
      <Hour key={hour.number} {...hour} />,
    );
  }

  private actionButtons() {
    const buttons = [];

    if (this.props.hours.length > this.state.show) {
      buttons.push(
        <Button
          key="addMoreHours"
          onClick={this.addMoreHours}
          text="More"
        />,
      );
    }

    if (this.state.show > this.hoursIncrement) {
      buttons.push(
        <Button
          key="subtractHours"
          onClick={this.subtractHours}
          text="Less"
        />,
      );
    }

    return buttons;
  }
}

interface ForecastProps {
  now: IForecast;
  later: IForecast;
}

function Forecast(props: ForecastProps) {
  const { now, later } = props;
  return <section className="forecast media">
    <div className="media-content">
      <p className="title is-2">{now.temperature}&#xb0;</p>
      <p className="subtitle is-4">
        {now.name}
      </p>
    </div>
    <div className="media-right">
      <figure className="image is-128x128">
        <img src={now.icon} alt="weather icon" />
      </figure>
    </div>
  </section>;
}

interface WeatherState {
  forecast: object;
  hourlyForecast: object;
  today: number;
  selectedDay: number;
  ready: boolean;
}

class Weather extends React.Component<{}, WeatherState> {
  public constructor(props) {
    super(props);
    const todaysDate = new Date();
    const today = todaysDate.getDay();
    this.state = {
      forecast: [],
      hourlyForecast: [],
      ready: false,
      selectedDay: today,
      today,
    };
  }

  public componentDidMount(): void {
    Promise.all([
      fetchAjax(noaaForecastURL, (noaaData) => {
        const periods = filterNextWeek(noaaData.properties.periods, "startTime");
        const forecastOrg = groupByDay(periods, "startTime");
        this.setState({forecast: forecastOrg});
      }),
      fetchAjax(noaaHourlyURL, (noaaData) => {
        const periods = filterNextWeek(noaaData.properties.periods, "startTime");
        const hourlyOrg = groupByDay(periods, "startTime");
        this.setState({hourlyForecast: hourlyOrg});
      }),
    ]).then((values) => this.setState({ready: true}));
  }

  public changeSelectedDay = (selection: number) => {
    this.setState({selectedDay: selection});
  }

  public renderDaySelection() {
    const daySelectors = [];
    for (let i = 0, len = DAYS.length; i < len; i++) {
      const pointer = (i + this.state.today) % DAYS.length;
      const dayChanger = (e: React.MouseEvent) => this.changeSelectedDay(pointer);
      daySelectors.push(<p
        key={`dayselector-${pointer}`}
        className="control"
      >
        <a
          className={this.state.selectedDay === pointer ? "button is-active is-small" : "button is-small"}
          onClick={dayChanger}
        >
          {DAYS[pointer]}
        </a>
      </p>);
    }
    return daySelectors;
  }

  public render() {
    if (this.state.ready) {
      const currentForecast = this.state.forecast[this.state.selectedDay];
      const currentHourly = this.state.hourlyForecast[this.state.selectedDay];
      return <>
        <h1 className="title is-1">Hus Weather</h1>
        <div className="field has-addons">
          {this.renderDaySelection()}
        </div>
        <Forecast
          now={currentForecast[0]}
          later={currentForecast[1]}
        />
        <HourlyList hours={currentHourly} show={5} />
      </>;
    } else {
      return <p>hold your horses</p>;
    }
  }
}

ReactDOM.render(
  <Weather />,
  document.getElementById("root"),
);
